import React, { useEffect, useRef, useState } from "react"
import { FiPhone } from "react-icons/fi"
import { PiChatsCircle } from "react-icons/pi"
import { RiKakaoTalkFill } from "react-icons/ri"

import { ToastContainer } from "react-toastify"
import { ALinkButton, Button, EmailIcon, LocationIcon, PhoneIcon, SelectBox } from "../../shared"

import "react-toastify/dist/ReactToastify.css"

const supportInfo = {
  section_1: {
    title: "고객 센터",
    time: "평일 09:00 - 18:00\n토요일 09:00 - 15:00\n일요일 휴무",
  },
  section_2: {
    contactMethods: [
      {
        icon: <FiPhone className="w-10 md:w-16 h-16 text-main-600 mb-4" />,
        title: "유선 문의",
        details: ["서울 02)512-6024"],
        buttonLabel: "전화연결",
        buttonLink: "tel:025126024",
      },
      {
        icon: <RiKakaoTalkFill className="w-10 md:w-16 h-16 text-main-600 mb-4" />,
        title: "카카오톡 상담",
        details: ["카카오톡에서 @GCC Group 검색", "톡상담 클릭시 상담 연결돼요."],
        buttonLabel: "톡상담연결",
        buttonLink: "http://pf.kakao.com/_kxexceM",
      },
      {
        icon: <PiChatsCircle className="w-10 md:w-16 h-16 text-main-600 mb-4" />,
        title: "문의 게시판",
        details: ["A/S 문의 및 기술 관련 문의사항을", "남겨주시면 답글을 통해 답변드려요."],
        buttonLabel: "문의게시판",
        buttonLink: "https://cafe.naver.com/barman",
      },
    ],
  },
  section_3: {
    title: "무엇을 도와드릴까요?",
    faqs: [
      "추출 시 에스프레소가 뚝뚝 떨어지거나 안 나와요.",
      "머신 그룹헤드에서 물이 안 나와요.",
      "머신에서 미지근한 물이 나오고 스팀이 안 나와요.",
      "A/S 접수는 어떻게 하나요?",
    ],
    ans: [
      "포터필터를 제거 후 물이 정상적으로 나오는지 확인해주세요. 물이 정상적으로 추출된다면 원두 분쇄도를 굵게 조절해서 확인해주세요.",
      "1. 건물이나 머신의 급수가 잠겨 있지는 않은지 혹은 정수필터 교체 주기가 지나지 않았는지 확인해주세요. 2. 위 문제가 없다면 엔지니어의 점검이 필요합니다.",
      "머신이 ECO 모드인지 확인해주세요.",
      "A/S 내선 번호 혹은 카카오톡 플러스 친구 GCC Group으로 문의 주시면 빠르게 응대해드리겠습니다.",
    ],
    more: "자주 묻는 질문 전체 보기 >",
  },
  section_4: {
    topic: "문의하기",
    title: "제너럴커피코디네이터와\n지금 함께 하세요",
    description:
      "리스/렌탈부터 창업 문의까지 제너럴커피코디네이터에 궁금한 점을 문의해 주세요.\n빠른 시일 내에 연락드리겠습니다.",
    email: "gcc@gccgroup.co.kr",
    phone: "02-512-6024",
    address: "대한민국 서울특별시 강남구 압구정로4길 13-11 4층",
  },
}

const inquiryTypes = [
  { label: "리스/렌탈 문의", value: "lease-lental" },
  { label: "컨설팅 문의", value: "consulting" },
  { label: "교육 문의", value: "education" },
  { label: "기타 문의", value: "other" },
]

export default function SupportPage() {
  const inquiryRef = useRef<HTMLDivElement>(null)
  const [activeFAQ, setActiveFAQ] = useState(null)
  const [formData, setFormData] = useState({
    email: "",
    inquiryType: "",
    message: "",
  })

  useEffect(() => {
    const hash = window.location.hash
    if (hash === "#inquiry") {
      inquiryRef.current?.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  const toggleFAQ = (index: any) => {
    if (activeFAQ === index) {
      setActiveFAQ(null)
    } else {
      setActiveFAQ(index)
    }
  }

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // 이메일 클라이언트를 열고 폼 데이터를 전송하는 mailto 링크 생성
    const mailtoLink = `mailto:${supportInfo.section_4.email}?subject=${encodeURIComponent(formData.inquiryType)}&body=${encodeURIComponent(
      `이메일: ${formData.email}\n\n문의 내용:\n${formData.message}`
    )}`

    window.location.href = mailtoLink
  }

  return (
    <>
      <section>
        <div className="relative">
          <img
            src="./images/lease_rental_banne.png"
            className="w-full h-[300px] md:h-[400px] object-cover"
          />
          <div className="absolute inset-0 bg-gray-800 bg-opacity-50"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-white text-4xl md:text-5xl font-bold">고객지원</span>
          </div>
        </div>
      </section>

      <section className="bg-sub-a-075">
        <div className="container max-w-[calc(100%-48px)] md:max-w-[1148px] mx-auto flex flex-col items-center text-center py-[100px] md:py-[150px] break-words break">
          <h2 className="text-3xl md:text-4xl font-bold mb-5">{supportInfo.section_1.title}</h2>
          <h2 className="text-base md:text-lg text-sub-a-700 whitespace-pre-line">
            {supportInfo.section_1.time}
          </h2>
        </div>
      </section>

      <section className="bg-white py-[100px] md:py-[150px]">
        <div className="container max-w-[calc(100%-48px)] desktop:max-w-[1148px] mx-auto flex flex-col desktop:flex-row justify-between items-start space-y-3 desktop:space-y-0 desktop:space-x-8 ">
          {supportInfo.section_2.contactMethods.map((method, index) => (
            <div
              key={index}
              className="flex flex-col items-center text-center p-6 border-gray-200 rounded-lg w-full desktop:w-1/3 desktop:h-[350px]"
            >
              {method.icon}
              <h2 className="text-xl md:text-3xl font-semibold mb-4">{method.title}</h2>
              <div className="text-gray-600 mb-4 text-base md:text-lg">
                {method.details.map((detail, index) => (
                  <p key={index}>{detail}</p>
                ))}
              </div>
              <div className="mt-auto">
                <ALinkButton
                  label={method.buttonLabel}
                  href={method.buttonLink}
                  bgColor="bg-gray-075"
                  textColor="text-black"
                />
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="bg-sub-a-075">
        <div className="container max-w-[1148px] mx-auto flex flex-col items-center text-center py-[100px] md:py-[150px]">
          <h2 className="text-3xl md:text-4xl font-bold mb-12">{supportInfo.section_3.title}</h2>

          <div className="max-w-[calc(100%-48px)] mx-auto w-full">
            <ul className="mb-12 w-full">
              {supportInfo.section_3.faqs.map((faq, index) => (
                <li key={index} className="flex flex-col items-start mb-6 w-full">
                  <button
                    onClick={() => toggleFAQ(index)}
                    className="text-base text-start md:text-[19px] py-3 px-4 w-full text-gray-700 hover:text-main-500 hover:bg-gray-200 rounded-md"
                  >
                    <span className="text-base md:text-[19px] text-gray-250 font-bold mr-2 self-start">
                      Q
                    </span>
                    {faq}
                  </button>
                  {activeFAQ === index && (
                    <div className="flex mt-2 px-6 text-start">
                      <span className="text-base md:text-[19px] text-gray-250 font-bold mr-2 self-start">
                        A
                      </span>
                      <p className=" text-gray-600 w-full text-start">
                        {supportInfo.section_3.ans[index]}
                      </p>
                    </div>
                  )}
                </li>
              ))}
            </ul>

            <div className="text-start px-4">
              <a
                href="http://pf.kakao.com/_kxexceM"
                className="text-base text-main-500 hover:text-main-700"
              >
                {supportInfo.section_3.more}
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white" ref={inquiryRef}>
        <div className="container max-w-[calc(100%-48px)] desktop:max-w-[1148px] mx-auto flex flex-col md:flex-row justify-between items-start space-y-8 md:space-y-0 md:space-x-8 py-[100px] md:py-[150px]">
          {/* Contact Information */}
          <div className="w-full md:w-1/2 rounded-lg">
            <h2 className="text-lg md:text-xl font-bold mb-4 text-main-500">
              {supportInfo.section_4.topic}
            </h2>
            <p className="text-3xl md:text-4xl font-bold mb-6 whitespace-pre-line">
              {supportInfo.section_4.title}
            </p>
            <p className="text-base md:text-lg text-sub-a-700 whitespace-pre-line mb-10 md:max-w-72">
              {supportInfo.section_4.description}
            </p>

            <div>
              <div className="flex items-center mb-3">
                <div className="mr-3">
                  <EmailIcon />
                </div>
                <a href={`mailto:${supportInfo.section_4.email}`} className="text-gray-600">
                  {supportInfo.section_4.email}
                </a>
              </div>
              <div className="flex items-center mb-3">
                <div className="mr-3">
                  <PhoneIcon />
                </div>
                <a href="tel:025126024" className="text-gray-600">
                  {supportInfo.section_4.phone}
                </a>
              </div>
              <div className="flex items-center mb-3">
                <div className="mr-3">
                  <LocationIcon />
                </div>
                <p className="text-gray-600">{supportInfo.section_4.address}</p>
              </div>
            </div>
          </div>

          {/* Inquiry Form */}
          <div className="w-full md:w-1/2 p-4 md:p-10 bg-white rounded-lg shadow-dropDown">
            <h2 className="text-xl md:text-3xl font-bold mb-4">문의하기</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  이메일
                </label>
                <input
                  className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                  placeholder="이메일을 입력해주세요."
                  id="email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="inquiryType">
                  문의 유형
                </label>
                <SelectBox
                  id="inquiryType"
                  name="inquiryType"
                  value={formData.inquiryType}
                  options={inquiryTypes}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                  문의 내용
                </label>
                <textarea
                  className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500 h-[200px]"
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  maxLength={2000}
                  placeholder="문의하실 내용을 입력해주세요. (최대 2000자)"
                ></textarea>
              </div>
              <div className="flex justify-end">
                <Button type="submit" label="문의 접수하기" bgColor="bg-main-500" />
              </div>
            </form>
          </div>
        </div>
      </section>

      <ToastContainer />
    </>
  )
}
